<style lang="scss" scoped>
    .driver-settle-amount /deep/ {
        .el-input__inner {
            border: 0;
        }
    }
</style>
<template>

    <div>
        <div class="product-list-table">
            <el-table
                    type="index"
                    class="table-info"
                    border
                    :data="dataList"
                    style="width: 100%"
            >

                <!--                v-loading="loading"-->
                <el-table-column prop="collectionName" label="司机姓名" width="100"></el-table-column>
                <el-table-column prop="reservedMobile" label="银行预留手机号" width="130"></el-table-column>
                <el-table-column prop="idCardNumber" label="身份证号" width="180"></el-table-column>
                <el-table-column prop="collectionName" label="收款人姓名" width="100"></el-table-column>
                <el-table-column prop="bankAccount" label="收款银行卡号" width="200"></el-table-column>
                <el-table-column label="结算金额" width="100">
                    <template slot-scope="scope">
                        <p>
                            <el-input class="driver-settle-amount" min="0" type="number"
                                      v-model="scope.row.driverSettlementAmount" placeholder="结算金额"
                                      :disabled="type != 2"
                                      @change="changeAmount(scope.$index, scope.row.driverSettlementAmount)"></el-input>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <div class="operation-btn clear">
                            <el-button v-if="dataList && dataList.length>1&&type==2"
                                       style="color:#FF3B30"
                                       @click="delBtn(scope.row, scope.$index)"
                                       type="text"
                            >删除
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {
                dataList: []
            }
        },
        props: {
            userInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            list: {
                type: Array,
                default: function () {
                    return []
                }
            },
            loading: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: ''
            },
            settleInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.dataList = this.list
        },
        //定义事件方法
        methods: {
            changeAmount(index, value) {
                if (value < 0) value = 0;
                this.$emit('update:selectSettleList', {index, value})
            }, // 改变时触发
            delBtn(item, idx) {
                this.$emit('update:handleDelBtn', item, idx)
            }, // 删除


        },
        //监听模版变量
        watch: {
            list(n) {
                this.dataList = n
            }
        }

    }
</script>

