<style lang="scss" scoped>

</style>
<template>

    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="fleet-list">
                <settle-list-search
                        @update:changeSettleValue="handleSettleValue"
                        @update:handleResetBtn="handleResetBtn"
                        @update:handleSearchBtn="handleSearchBtn"
                        @update:handleExportBtn="handleExportBtn"
                        ref="settleListStateNum"
                >
                </settle-list-search>
                <settle-list-table
                        style="margin-top: 10px"
                        :dataList="settleList.data"
                        :loading="listLoading"
                        :userInfo="userInfo"
                        @update:handleEditBtn="handleEditBtn"
                        @update:handleSettleBtn="handleSettleBtn"
                        @update:handleSeeBtn="handleSeeBtn"
                        @update:handleDelBtn="handleDelBtn"
                >
                </settle-list-table>
            </section-content>

        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                            @current-change="initList"
                            :current-page.sync="pageInfo.pageIndex"
                            :page-size="pageInfo.pageSize"
                            layout="total, prev, pager, next"
                            :total="pageInfo.total"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>


        <del-list-dialog
                :show="isDelShow"
                :listData="driverInfoList"
                @update:submitListBtn="handleSubmitListBtn"
                @update:handleCancelBtn="handleDelCancelBtn"
                ref="delSelectDriver"
        >
        </del-list-dialog>

    </div>

</template>

<script type="text/ecmascript-6">
    import settleListSearch from "./components/search/index"
    import settleListTable from "./components/table/index"


    import delListDialog from "./components/delListDialog"

    export default {
        //定义模版数据
        data() {
            return {
                form: {},
                pageInfo: {
                    pageSize: 10, // 每页显示条目个数
                    total: 1, // 总条目个数
                    pageIndex: 1 // 当前页数
                },
                listLoading: false,
                isDelShow: false,

                driverInfoList: {}
            }
        },
        components: {
            settleListSearch,
            settleListTable,
            delListDialog
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo;
            },
            settleList() {
                let data = this.$store.state.settlement.settleList;
                return data
            },
            delPayState() {
                let data = this.$store.state.settlement.delPaySettle;
                return data
            }
        },
        //主件被加载完成
        mounted: function () {
            this.initList(1);
        },
        //定义事件方法
        methods: {
            initList(index) {
                let {
                        settlementStatus,
                        supplierId,
                        startTime,
                        endTime,
                        settlementAmount
                    } = this.form,
                    form = {
                        pageIndex: index,
                        pageSize: this.pageInfo.pageSize,
                        settlementStatus,
                        supplierId,
                        startTime,
                        endTime,
                        settlementAmount
                    }

                this.$refs.settleListStateNum.initData()
                this.$store.dispatch('settleList', {form, _this: this})
            },
            handleSettleValue(v) {
                this.form.settlementStatus = v
                this.initList(1)
            },
            handleResetBtn() {
                this.form = {}
                this.initList(1)
            },  // 重置
            handleSearchBtn(item) {
                this.form = item
                this.initList(1)
            },  // 搜索
            handleExportBtn(item){
                this.form = item
                this.$store.dispatch('exportPaySettle', {form: this.form, _this: this})
            },
            handleSeeBtn(item) {
                this.$router.push(`/editSettle/${item.id}/0`);
            },  // 查看
            handleSettleBtn(item) {
                this.$router.push(`/editSettle/${item.id}/1`);
            },  // 结算
            handleEditBtn(item) {
                console.log(item, "编辑");
                this.$router.push(`/editSettle/${item.id}/2`);
            },  // 编辑
            handleDelBtn(obj) {
                this.driverInfoList = {};
                this.isDelShow = true;
                this.driverInfoList = obj

            },  // 删除
            handleDelCancelBtn() {
                this.isDelShow = false;
                this.driverInfoList = {};
            },  // 取消
            handleSubmitListBtn(obj) {
                let form = {
                    id: obj.id
                };
                this.$store.dispatch('delPaySettle', {form, _this: this});
                if (this.delPayState) {
                    this.isDelShow = false;
                    this.driverInfoList = {};
                    this.initList(1)
                }
            },  // 删除

        },
        //监听模版变量
        watch: {
            settleList(n) {
                this.pageInfo.total = n.total
            }
        }

    }
</script>

