<style lang="scss" scoped>
    .add-form-first /deep/ {
        .el-form-item__content {
            display: flex;
        }
    }
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <el-form :model="form" :rules="rules" ref="form" label-width="120px" size="small">
                    <div v-for="(item, index) in form.addSettleList" :key="index"
                         style="background: #fff;margin-bottom: 10px;padding: 20px">
                        <el-form-item label="供应商："
                                      class="add-form-first"
                                      :prop="'addSettleList[' + index + '].supplierInfo.supplierName'"
                                      :rules="{required: true, message: '请填写供应商', trigger: 'change'}"
                                      style="width: 426px">
                            <supplier-info-input
                                    style="width: 100%"
                                    :dataType="1"
                                    :index="index"
                                    :companyName="item.supplierInfo.supplierName"
                                    @update:getSelectSupplier="getSelectSupplier"
                                    ref="selectSupplierInfo"
                            >
                            </supplier-info-input>
                            <el-button type="text" style="display: inline-block;color: #FF3B30;margin-left: 20px"
                                       @click="handleDelBtn(index)"
                                       v-if="form.addSettleList && form.addSettleList.length > 1">删除
                            </el-button>
                        </el-form-item>
                        <el-form-item label="结算总金额：" :prop="'addSettleList[' + index + '].settlementAmount'"
                                      :rules="{required: true, message: '请填写结算总金额', trigger: 'change'}"
                                      style="width: 380px">
                            <el-input v-model="item.settlementAmount" placeholder="结算金额"
                                      min="0"
                                      type="number"
                                      maxlength="10"
                                      prefix-icon="icon icon-daifukuan"
                                      @change="changeSettleAmount(index)"></el-input>
                        </el-form-item>
                        <el-form-item label="结算周期：" style="width: 380px"
                                      :prop="'addSettleList[' + index + '].settleCycleDate[0]'"
                                      :rules="{required: true, message: '请选择结算周期', trigger: 'change'}">
                            <el-date-picker
                                    style="width: 100%"
                                    v-model="item.settleCycleDate"
                                    value-format="yyyy-MM-dd"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="选择开始日期"
                                    end-placeholder="选择结束日期">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="备注：" style="width: 640px">
                            <el-input v-model="item.remark" placeholder="填写备注信息"></el-input>
                        </el-form-item>


                    </div>


                </el-form>

                <div style="display: flex; padding: 20px;background: #fff">

                    <el-button type="primary" @click="handleAddSettleBtn" size="small">+ 新增</el-button>
                    <ul style="color: #909399;font-size: 12px;line-height: 16px;margin-left: 16px">
                        <li>1、可添加多条内容，批量提交。</li>
                        <li>2、提交后生成的结算记录，将按条例出。</li>
                    </ul>
                </div>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont" style="text-align: right">

                <el-popover
                        title="确认提交"
                        placement="top-end"
                        width="280"
                        trigger="click"
                        v-model="submitShow">
                    <p>我已确认填写内容及金额</p>
                    <div style="text-align: right; margin: 0">
                        <el-button style="width: 110px" @click="submitShow = false">取消</el-button>
                        <el-button style="width: 110px" type="primary" @click="submitBtn">确定</el-button>
                    </div>
                    <el-button slot="reference" type="primary" style="width: 121px">提交</el-button>
                </el-popover>
            </div>
        </el-footer>
    </div>

</template>

<script type="text/ecmascript-6">
    import supplierInfoInput from "../../../components/supplierInfoInput/index"
    export default {
        //定义模版数据
        name: "addSettle",
        data() {
            return {
                submitShow: false,
                form: {
                    addSettleList: [{
                        supplierInfo: {},
                        settlementAmount: '',
                        settleCycleDate: '',
                        remark: ''

                    }],
                },
                rules: {},
                isShow: false,

                settleInfoList: [],

                visible2: false

            }
        },
        components: {
            supplierInfoInput
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            changeSettleAmount(index) {
                this.form.addSettleList.forEach((item, idx) => {
                    if (index == idx) {
                        if (item.settlementAmount < 0) item.settlementAmount = 0;
                        if (item.settlementAmount.length > 10) {
                            this.$message.error('请输入有效金额');
                            return
                        }
                        item.settlementAmount = parseFloat(item.settlementAmount).toFixed(2);
                        // item.settlementAmount = keepTwoDecimal(item.settlementAmount)
                    }
                })
            },  // 结算总金额格式化
            getSelectSupplier(item, index) {
                this.form.addSettleList[index].supplierInfo = item
                console.log(index);
            }, // 供应商
            handleAddSettleBtn() {
                let data = []
                data.push({
                    supplierInfo: {},
                    // supplierName: '',
                    settlementAmount: '',
                    settleCycleDate: '',
                    remark: ''
                })
                this.form.addSettleList = [...this.form.addSettleList, ...data]
            },
            handleDelBtn(idx) {
                let addList = this.form.addSettleList
                addList.forEach((item, index) => {
                    if (idx == index) {
                        addList.splice(idx, 1)
                    }
                })
                this.form.addSettleList = addList
            },
            submitBtn() {
                if(this.loading) return;
                this.loading = true;
                this.submitShow = false
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        let addSettleItem = JSON.parse(JSON.stringify(this.form.addSettleList));
                        addSettleItem.forEach(item => {
                            if (item.settleCycleDate && item.settleCycleDate.length === 2) {
                                item.startTime = +new Date(item.settleCycleDate[0]);
                                item.endTime = +new Date(item.settleCycleDate[1] + " 23:59:59");
                                delete item.settleCycleDate
                            }
                            if (item.supplierInfo.supplierId) {
                                item.supplierId = item.supplierInfo.id;
                                delete item.supplierInfo
                            }
                        });
                        let form = {
                            paySettlementDtos: addSettleItem
                        }
                        this.loadingShow();
                        let res = await this.$store.dispatch('addSettleRecord', {form, _this: this})
                        if (res) {
                            this.form = {
                                addSettleList: [{
                                    supplierInfo: {},
                                    settlementAmount: '',
                                    settleCycleDate: '',
                                    remark: ''

                                }],
                            };
                            this.$store.commit('delete_tabs', this.$route.path);
                            this.$router.replace('/settleList');
                        }
                        this.loading = false;
                        this.loadingHide();
                    } else {
                        this.loading = false;
                        return false;
                    }
                })


            },

        },
        //监听模版变量
        watch: {}

    }
</script>

