<template>

    <div>
        <el-autocomplete
            style="width: 100%"
            v-model="supplierName"
            :fetch-suggestions="handleSupplier"
            placeholder="输入供应商名称"
            :trigger-on-focus="trigger"
            :disabled="isEdit"
            @select="select"
            @blur="blur"
            @clear="clear"
            :clearable="clearable">
            <template slot-scope="{ item }">
                <div class="list-pop-bot">
                    <span>{{ item.label }}</span>
                </div>
            </template>
        </el-autocomplete>

    </div>

</template>

<script type="text/ecmascript-6">
export default {
    //定义模版数据
    name: "supplierInfoInput",
    data() {
        let supplierUrlId = "/galaxySupplier/doGalaxyGetSupplierInfoByOperatorId"
        return {
            supplierName: '',
            supplierUrlId
        }
    },
    props: {
        dataType: {
            type: Number,
            default: 0,
        },// 0 根据operatorId（运营商id）查询供应商接口  1 根据companyName（输入公司名称）查询供应商接口
        operatorInfo: {
            type: Object,
            default: function () {
                return {}
            },
        }, // 当前运营商传值
        companyName: {
            type: String,
            default: ''
        },  // 输入公司名称
        selectSupplierId: {
            type: String,
            default: ''
        },  // 当前选中的供应商id
        isEdit: {
            type: Boolean,
            default: false
        },  // 是否编辑
        clearable: {
            type: Boolean,
            default: false
        },  // 是否清除  可配合@clear 清除事件
        index: {
            type: Number,
            default: 0
        },
        trigger: {
            type: Boolean,
            default: false,
        },   // 是否显示建议列表
        clearSupplier: {
            type: Function,
            default: null
        }
    },
    components: {},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {
        if (this.companyName) this.supplierName = this.companyName;
        this.getSelectSupplierInfo()
    },
    //定义事件方法
    methods: {
        clearSupplierName() {
            this.supplierName = '';
            this.name = ''
        },
        filter(item) {
            return (res) => {
                return res.supplierName && res.supplierName.indexOf(item) === 0;
            };
        },
        async handleSupplier(supplier, cb) {
            let companyName = this.supplierName;
            let res;
            switch (this.dataType) {
                case 0:
                    res = await this.http(this.supplierUrlId, {operatorId: this.operatorInfo.id}, "POST");
                    break;
                case 1:
                    res = await this.http("/galaxySupplier/supplierInfoPageList", {companyName}, "POST");
            }
            let list = [];
            if (res.data && res.success) {
                if (this.dataType == 0) {
                    list = res.data;
                } else {
                    list = res.data.data;
                }
                list.forEach(item => {
                    item.value = item.id;
                    item.label = item.supplierName;
                });
                let supplierList = supplier ? list.filter(this.filter(supplier)) : list;
                cb(supplierList);
            }
        },  // 供应商列表
        select(item) {
            this.supplierName = item.supplierName;
            this.name = item.supplierName;
            this.$emit('input', item.id, this.index);
            this.$emit('update:getSelectSupplier', item, this.index)

        },  // 当前选中供应商
        blur() {
            this.supplierName = this.name;
        },
        clear() {
            this.clearSupplierName();
            if (this.clearSupplier) {
                this.clearSupplier()
            }
            this.$emit('clear')
        },
        async getSelectSupplierInfo() {
            let list = [];
            let res = await this.http(this.supplierUrlId, {operatorId: this.operatorInfo.id}, "POST");
            if (res.data && res.success) {
                list = res.data;
                list.forEach(item => {
                    if (item.id == this.selectSupplierId) {
                        this.supplierName = item.supplierName;
                        this.select(item)
                    }
                })
            }
        }  // 当前选中供应商id查询供应商详情

    },
    //监听模版变量
    watch: {
        "selectSupplierId": function (n) {
            if (n) {
                this.getSelectSupplierInfo()
            }
        },
        companyName(n) {
            this.supplierName = n
        },
    }

}
</script>

