<style lang="scss" scoped>
    .mod-driver-confirm /deep/ {
        .add-dialog-info {
            max-height: 200px;
            overflow: hidden;
            overflow-y: scroll;
        }

        .add-dialog-info_list {
            span {
                display: inline-block;
            }

            .list1 {
                width: 80px;
            }

            .list2 {
                width: 100px;
            }

            .list3 {
                width: 80px;
            }

            .list4 {
                width: 150px;
            }
        }

        .el-checkbox {
            line-height: 22px;
            margin-right: 0;
            margin-bottom: 4px;
            display: block;

            .el-checkbox__label {
                display: inline-block;
            }
        }
    }

</style>
<template>

    <div>
        <el-dialog title="确认删除"
                   width="420px"
                   :close-on-click-modal="false"
                   :show-close="false"
                   custom-class="el-dialog-middle-center"
                   class="mod-driver-confirm"
                   :visible.sync="show">
            <div class="add-dialog-info">
                <ul>
                    <li>结算记录ID：{{list.settlementNo}}</li>
                    <li>创建时间：{{list.createTime | dateCus('yyyy-MM-dd')}}</li>
                    <li>供应商：{{list.supplierName}}</li>
                </ul>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancelBtn">取 消</el-button>
                <el-button size="small" type="primary" @click="submitBtn()">确 定</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script type="text/ecmascript-6">

    export default {
        //定义模版数据
        name: "addInfoDialog",
        data() {
            return {
                list: {},
            }
        },
        props: {
            show: {
                type: Boolean,
                default: false
            }, // 弹窗是否显示
            listData: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            cancelBtn() {
                this.$emit('update:handleCancelBtn');
            },  // 取消
            submitBtn() {
                this.$emit('update:submitListBtn', this.list)
            },  // 确认

        },
        //监听模版变量
        watch: {
            listData(n) {
                this.list = n
            }
        }

    }
</script>

