<style lang="scss" scoped>
    .common-list-search .list-search-before .el-form-item.el-form-item--small {
        width: auto;
    }

    .settle-list-search /deep/ {
        &.common-list-search .list-search-before {
            width: calc(100% - 260px);
        }
        &.common-list-search .list-search-after {
            width: 260px;
        }
    }


</style>
<template>
    <div>
        <com-list-search class="settle-list-search">
            <div slot="list-search-before">
                <el-form :inline="true" :model="form" size="small">

                    <el-form-item label="">
                        <el-input v-model="form.collectionName" style="width: 110px;" placeholder="司机姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input v-model="form.reservedMobile" style="width: 110px;" placeholder="手机号"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <supplier-info-input
                                :dataType="1"
                                @update:getSelectSupplier="getSelectSupplier"
                                ref="clearSupplierInfo"
                        >
                        </supplier-info-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="exportExcel()" size="small">导出</el-button>
                        <el-button @click="reset()" size="small">重置</el-button>
                        <el-button type="primary" @click="search()" size="small">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="list-search-after" style="text-align: right">
                <el-button type="primary" @click="qrCodeBtn()" size="small">二维码｜新增司机结算信息</el-button>
            </div>
        </com-list-search>


    </div>

</template>

<script type="text/ecmascript-6">

    import comListSearch from "../../../../../components/comListSearch/index.vue";
    import supplierInfoInput from "../../../../../components/supplierInfoInput/index"

    export default {
        //定义模版数据
        data() {
            return {
                form: {},
            }
        },
        props: {},
        components: {
            comListSearch,
            supplierInfoInput,
            // operatorInfoInput
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo;
            }
        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            reset() {
                this.form = {
                    collectionName: '',
                    reservedMobile:'',
                    supplierInfo: {},
                };
                this.$refs.clearSupplierInfo.clearSupplierName();
                this.$emit('update:resetBtn', this.form)
            },
            search() {
                this.$emit('update:searchBtn', this.form)
            },
            getSelectSupplier(item) {
                this.form.supplierInfo = item
            }, // 供应商
            exportExcel(){
                this.$emit('update:exportExcelBtn', this.form)
            },  // 导出


            // searchFleetInfo() {
            //     this.$emit('update:searchFleetInfo', this.form)
            // },



            qrCodeBtn(){
                this.$emit('update:handleQrCodeBtn')
            }
        },
        //监听模版变量
        watch: {}

    }
</script>

