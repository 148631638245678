<style lang="scss" scoped>
    .mod-driver-confirm /deep/ {
        .add-dialog-info {
            max-height: 200px;
            overflow: hidden;
            overflow-y: scroll;
        }

        .add-dialog-info_list {
            span {
                display: inline-block;
            }

            .list1 {
                width: 80px;
            }

            .list2 {
                width: 100px;
            }

            .list3 {
                width: 80px;
            }

            .list4 {
                width: 150px;
            }
        }

        .el-checkbox {
            line-height: 22px;
            margin-right: 0;
            margin-bottom: 4px;
            display: block;

            .el-checkbox__label {
                display: inline-block;
            }
        }
    }

</style>
<template>

    <div>
        <el-dialog title="添加司机"
                   width="420px"
                   :close-on-click-modal="false"
                   :show-close="false"
                   custom-class="el-dialog-middle-center"
                   class="mod-driver-confirm"
                   :visible.sync="show">
            <div class="add-dialog-info">
                <el-checkbox-group v-model="addSelectList" v-if="list && list.length > 0">
                    <!--                    @change="changeAddSelect"-->
                    <el-checkbox v-for="(item, index) in list" :key="index" :label="item.id"
                                 :value="item.id">
                        <span class="add-dialog-info_list">
                            <span class="list1">{{item.collectionName}}</span>
                            <span class="list2">{{item.reservedMobile}}</span>
                            <span class="list4">{{item.idCardNumber}}</span>
                        </span>
                    </el-checkbox>
                </el-checkbox-group>
                <div v-else>
                    当前无司机可选
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancelBtn">取 消</el-button>
                <el-button size="small" type="primary" @click="submitBtn()">确 定</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script type="text/ecmascript-6">

    export default {
        //定义模版数据
        name: "addInfoDialog",
        data() {
            return {
                addSelectList: [],
                list: [],
            }
        },
        props: {
            show: {
                type: Boolean,
                default: false
            }, // 弹窗是否显示
            supplierId: {
                type: String,
                default: ''
            },
            listData: {
                type: Array,
                default: function () {
                    return []
                }
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            cancelBtn() {
                this.addSelectList = []
                this.$emit('update:handleCancelBtn');
            },  // 取消
            submitBtn() {
                let selectList = [];
                this.addSelectList.forEach(item => {
                    this.list.forEach(list => {
                        if (list.id == item) {
                            list.driverSettlementId = list.id;
                            delete list.id;
                            selectList.push(list)
                        }
                    })
                });
                if (selectList && selectList.length > 0) {
                    this.$emit('update:selectList', selectList)
                } else {
                    this.$message.error('请选择司机');
                }
            },  // 选中司机或车辆
            delFleetId(id){
                if(id){
                    this.addSelectList.forEach((item, index) => {
                        if(item == id){
                            this.addSelectList.splice(index, 1)
                        }
                    })
                }
            }

        },
        //监听模版变量
        watch: {
            listData(n){
                this.list = n
            }
        }

    }
</script>

