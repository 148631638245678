<style lang="scss" scoped>

    @import "supplier-qr-code-dialog";

    .mod-driver-confirm /deep/ {
        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            background: #DADADA;
            text-align: center;
            padding: 75px 0;
        }
    }
</style>
<template>

    <div>
        <el-dialog title=""
                   width="480px"
                   :close-on-click-modal="false"
                   :show-close="false"
                   custom-class="el-dialog-middle-center"
                   class="mod-driver-confirm"
                   :visible.sync="isShow">
            <div class="check-demand-info">
                <div @click="cancelBtn" style="position: absolute;right: 20px;top: 20px;font-size: 30px;">
                    <i class="el-icon-close"></i>
                </div>
                <div class="code-before">
                    <div class="code-before-cont" :id="addQrCodeInfo.id">
                        <h3>{{addQrCodeInfo.title}}</h3>
                        <h4>{{addQrCodeInfo.companyName}}</h4>
                        <h5>{{addQrCodeInfo.subTitle}}</h5>
                        <div class="code-section" :style="sectionStyle">
                            <div class="code-company-href">
                                <qr-code-vue :value="addQrCodeInfo.codeValue" :size="234" level="H"></qr-code-vue>
                            </div>
                            <p class="code-distinguish">{{addQrCodeInfo.tips}}</p>
                        </div>
                    </div>
                </div>
                <div class="code-after" style="width: 300px;height: 358px;margin: auto">
                    <img v-if="qrCodeAfter" :src="qrCodeAfter" style="width: 300px;height: 358px;vertical-align: middle;"/>
                    <p style="font-size: 14px;line-height: 14px;padding-top: 172px" v-else>正在加载中,请稍后</p>
                </div>

                <el-button size="small" type="primary" @click="downloadImgSave(qrCodeAfter)" style="margin-top: 16px; width: 300px">保存图片</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script type="text/ecmascript-6">

    import config from '../../../../../../config/';
    import QrCodeVue from 'qrcode.vue'
    import html2canvas from 'html2canvas'

    export default {
        //定义模版数据
        data() {
            return {
                qrCodeAfter: null,
                sectionStyle: {background: 'url(' + require('@/assets/images/home_code_bg.jpg') + ')'}
            }
        },
        props: {
            isShow: {
                type: Boolean,
                default: false
            },
        },
        components: {
            QrCodeVue
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo
            },
            codeId() {
                let id = 'etripSettleCode';
                return id
            },
            addQrCodeInfo() {
                let data = {
                    id: this.codeId,
                    title: '新增司机结算信息',
                    companyName: this.userInfo.companyName,
                    subTitle: '保存图片，请司机扫码填写',
                    codeValue: config.protocol + '//' + (config.Debug ? 'driver-h5-emu.900etrip.com' : 'driver-h5.900etrip.com')
                        + '/?orgId=' + this.userInfo.refId + '&' + 'orgName=' + this.userInfo.companyName + '&path=bankCard#/login',
                    codeDistinguish: '微信扫码或长按识别',
                    tips: '请司机微信扫码，填写并提交个人信息'
                }
                return data
            },

        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            handleQrCode() {
                setTimeout(() => {
                    this.codeCanvasImg(this.codeId)
                }, 200)
            },
            codeCanvasImg(codeCanvas) {
                let codeCanvasId = document.getElementById(codeCanvas);
                let _this = this;
                if (codeCanvasId) {
                    html2canvas(codeCanvasId).then(function (canvas) {
                        let imgUrl = canvas.toDataURL('image/png', 1);
                        if (_this.addQrCodeInfo.id === _this.codeId) {
                            _this.qrCodeAfter = imgUrl
                        }
                    });
                }
            },
            downloadImgSave(url){
                var down = document.createElement("a");
                down.download = this.addQrCodeInfo.companyName + '新增司机结算信息';// 设置下载的文件名，默认是'下载'
                down.href = url;
                document.body.appendChild(down);
                down.click();
                down.remove(); // 下载之后把创建的元素删除
            },
            cancelBtn() {
                this.qrCodeAfter = null;
                this.$emit('update:cancelBtn')
            },

        },
        //监听模版变量
        watch: {
            qrCodeAfter(n) {
                this.qrCodeAfter = n
            }
        }

    }
</script>

