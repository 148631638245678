<style lang="scss" scoped>

</style>
<template>

    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="fleet-list">
                <settle-search
                        @update:searchBtn="handleSearchBtn"
                        @update:resetBtn="handleResetBtn"
                        @update:exportExcelBtn="handleExportExcelBtn"
                        @update:handleQrCodeBtn="handleQrCodeBtn"></settle-search>
                <settle-table
                        style="margin-top: 10px"
                        :dataList="driverSettleList.data"
                        :loading="listLoading"
                        :userInfo="userInfo"
                        @update:handleEditBtn="handleEditBtn"
                        @update:handleDelBtn="handleDelBtn"
                >
                </settle-table>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                            @current-change="initData"
                            :current-page.sync="pageInfo.pageIndex"
                            :page-size="pageInfo.pageSize"
                            layout="total, prev, pager, next"
                            :total="$store.state.settlement.driverSettleList.total"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>

        <edit-settle-dialog
                :is-show="isSettleShow"
                :info="editSelectInfo"
                @update:handleCancelBtn="handleCancelBtn"
                @update:handleSubmitBtn="handleSubmitBtn">
        </edit-settle-dialog>

        <supplier-qr-code-dialog
                ref="settleQrCode"
                :is-show="isQrCodeShow"
                @update:cancelBtn="qrCodeCancelBtn">

            <!--            :info=""-->

        </supplier-qr-code-dialog>


    </div>

</template>

<script type="text/ecmascript-6">
    import settleSearch from "./components/settleSearch/index"
    import settleTable from "./components/settleTable/index"
    import editSettleDialog from "./components/editSettleDialog/index"
    import supplierQrCodeDialog from "./components/supplierQrCodeDialog/index"

    export default {
        //定义模版数据
        data() {
            return {
                formData: {},
                pageInfo: {
                    pageSize: 10, // 每页显示条目个数
                    total: 1, // 总条目个数
                    pageIndex: 1 // 当前页数
                },
                listLoading: false,
                isSettleShow: false,
                editSelectInfo: {},
                isQrCodeShow: false
                ,
            }
        },
        components: {
            settleSearch,
            settleTable,
            editSettleDialog,
            supplierQrCodeDialog
        },
        //计算属性
        computed: {

            userInfo() {
                return this.$store.state.loginUserInfo;
            },
            driverSettleList() {
                return this.$store.state.settlement.driverSettleList;
            },
            delDriverSettle() {
                let data = this.$store.state.settlement.delDriverSettle;
                return data
            },
            editDriverSettle() {
                let data = this.$store.state.settlement.editDriverSettle;
                return data
            },
        },
        //主件被加载完成
        mounted: function () {
            this.initData(1)
        },
        //定义事件方法
        methods: {
            initData(index) {
                let {
                        collectionName,
                        reservedMobile,
                        supplierInfo
                    } = this.formData,
                    form = {
                        pageIndex: index,
                        pageSize: this.pageInfo.pageSize,
                    };
                if (supplierInfo) form.supplierId = supplierInfo.id;
                if (collectionName) form.collectionName = collectionName;
                if (reservedMobile) form.reservedMobile = reservedMobile;
                this.$store.dispatch('driverSettleList', {form, _this: this})
            },

            handleSearchBtn(item) {
                this.formData = item;
                this.initData(1)
            },
            handleResetBtn() {
                this.formData = {}
                this.initData(1)
            },  // 重置
            handleExportExcelBtn(item) {
                this.formData = item;
                let {
                        collectionName,
                        reservedMobile,
                        supplierInfo
                    } = this.formData,
                    form = {};
                if (supplierInfo) form.supplierId = supplierInfo.id;
                if (collectionName) form.collectionName = collectionName;
                if (reservedMobile) form.reservedMobile = reservedMobile;
                this.$store.dispatch('exportDriverSettle', {form, _this: this})

            },  // 导出

            handleQrCodeBtn() {
                console.log(33);
                this.$refs.settleQrCode.handleQrCode()
                this.isQrCodeShow = true
            },   // 弹窗 司机二维码
            qrCodeCancelBtn() {
                this.isQrCodeShow = false
            },

            handleEditBtn(item) {
                this.editSelectInfo = item;
                this.isSettleShow = true;

            },  // 编辑
            handleDelBtn(obj) {
                let form = {
                    id: obj.id
                };
                this.$confirm('确认删除司机信息, 是否继续?', '确认删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$store.dispatch('delDriverSettle', {form, _this: this});
                    if (this.delDriverSettle) {
                        this.initData(1)
                    }
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });


            },  // 删除
            handleCancelBtn(bool) {
                this.isSettleShow = bool
            },  // 取消
            handleSubmitBtn(item) {
                let {
                        id,
                        reservedMobile,
                        bankAccount
                    } = item,
                    form = {
                        id,
                        reservedMobile,
                        bankAccount
                    }
                this.$store.dispatch('editDriverSettle', {form, _this: this});

                if (this.editDriverSettle) {
                    this.isSettleShow = false;
                    this.initData(1)
                }
            },  // 提交


        },
        //监听模版变量
        watch: {}

    }
</script>

