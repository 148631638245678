<style lang="scss" scoped>
    .add-form-first /deep/ {
        .el-form-item__content {
            display: flex;
        }
    }

    .edit-settle-head {
        font-size: 14px;
        padding: 20px 50px;
        display: flex;

        li {
            padding-right: 50px;
        }
    }
</style>
<template>
    <div>
        <el-main class="com-eTrip-section" :class="settleType != 0?'section-has-footer':''">
            <div class="eTrip-section-cont" style="background: #fff">
                <ul class="edit-settle-head" style="">
                    <li>状态：<span>{{settleInfo.settlementStatus | statusDesc}}</span></li>
                    <li>结算记录ID：{{settleInfo.settlementNo}}</li>
                    <li>创建时间：{{settleInfo.createTime | dateCus('yyyy-MM-dd')}}</li>
                </ul>
                <el-form :model="form" :rules="rules" ref="form" label-width="120px" size="small">
                    <el-form-item label="供应商："
                                  class="add-form-first"
                                  style="width: 426px">
                        <supplier-info-input
                                style="width: 100%"
                                :is-edit="true"
                                :dataType="1"
                                :selectSupplierId="settleInfo.supplierId"
                        >
                        </supplier-info-input>
                    </el-form-item>
                    <el-form-item label="结算总金额："
                                  style="width: 380px">
                        <el-input v-model="settleInfo.settlementAmount" placeholder="结算金额"
                                  prefix-icon="icon icon-daifukuan"
                                  :disabled="userInfo.userType != 0 || settleType == 0 || settleInfo.settlementStatus == 1"
                                  @change="changeSettleAmount(settleInfo.settlementAmount)"
                                  ></el-input>
                    </el-form-item>
                    <!--                    :prop="settleInfo.settleCycleDate[0]"-->
                    <el-form-item label="结算周期：" style="width: 380px">
                        <el-date-picker
                                style="width: 100%"
                                v-model="settleInfo.settleCycleDate"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="选择开始日期"
                                end-placeholder="选择结束日期"
                                :disabled="userInfo.userType != 0 || settleType == 0 || settleInfo.settlementStatus == 1"
                                :clearable="false">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="备注：" style="width: 910px" v-if="userInfo.userType == 0 || settleType == 0">
                        <el-input v-model="settleInfo.remark" placeholder="填写备注信息"
                                  :disabled="settleType == 0"></el-input>
                        <div style="background:#E6F7FF;font-size: 14px;height: 38px;line-height: 38px;padding: 0 20px;margin-top: 15px;border-radius: 4px;
border:1px solid #BAE7FF;"><i class="el-icon-warning" style="color: #1890FF;margin-right: 5px"></i>温馨提示：1.合计金额明细之和=结算总金额。
                            2.员工单月发放金额3万元以下的，无个税；3万（含）-10万，个税1%
                        </div>
                    </el-form-item>


                    <el-form-item label="结算金额明细：" style="padding: 20px;background: #fff">
                        <div style="display: flex">
                            <el-button type="primary" @click="addSettleInfo" v-if="settleType == 2">+ 新增结算明细</el-button>
                            <div :style="{marginLeft: (settleType == 2?'25px':'')}" style="margin-right: 25px">当前合计：{{totalAmount}}</div>
                            <span style="font-size: 14px;color: #909399">合计金额明细之和=结算总金额</span>
                        </div>

                        <div style="width: 911px;margin-top: 20px ">
                            <settle-info-table
                                    :type="settleType"
                                    :list="settleInfoList"
                                    :settleInfo="settleInfo"
                                    @update:handleDelBtn="handleDelSettleBtn"
                                    @update:selectSettleList="handleSelectSettleList"
                            >
                            </settle-info-table>
                        </div>


                    </el-form-item>

                </el-form>

            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px" v-if="settleType != 0">
            <div class="eTrip-footer-cont" style="text-align: right">
                <el-popover
                        v-if="settleType == 1"
                        title="确认结算"
                        placement="top-end"
                        width="280"
                        trigger="click"
                        v-model="settleShow">
                    <p style="padding-bottom: 10px">我已确认结算内容，同意导出结算凭证并发送</p>
                    <div style="text-align: right; margin: 0">
                        <el-button style="width: 110px" @click="settleShow = false">取消</el-button>
                        <el-button style="width: 110px" type="primary" @click="submitSettleBtn">确定</el-button>
                    </div>
                    <el-button slot="reference" type="primary" style="width: 121px">结算</el-button>
                </el-popover>
                <el-popover
                        v-if="settleType == 2"
                        title="保存"
                        placement="top-end"
                        width="280"
                        trigger="click"
                        v-model="preserveShow">
                    <p style="padding-bottom: 10px">保存已填写的内容</p>
                    <div style="text-align: right; margin: 0">
                        <el-button style="width: 110px" @click="preserveShow = false">取消</el-button>
                        <el-button style="width: 110px" type="primary" @click="submitCreateBtn">确定</el-button>
                    </div>
                    <el-button slot="reference" type="primary" style="width: 121px">保存</el-button>
                </el-popover>
            </div>
        </el-footer>

        <select-driver-dialog
                :show="isShow"
                :listData="driverInfoList"
                @update:selectList="handleSelectList"
                @update:handleCancelBtn="handleCancelBtn"
                ref="delSelectDriver"
        >
        </select-driver-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    import supplierInfoInput from "../../../components/supplierInfoInput/index"

    import settleInfoTable from "./components/settleInfoTable"
    import selectDriverDialog from "./components/selectDriverDialog"


    import dataInfo from "../data/index"


    export default {
        //定义模版数据
        data() {
            return {
                preserveShow: false,
                settleShow: false,
                form: {},
                rules: {},
                isShow: false,
                settleInfoList: [],
                totalAmount: 0,
            }
        },
        components: {
            supplierInfoInput,
            settleInfoTable,
            selectDriverDialog
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo
            },
            settleId() {
                return this.$route.params.id;
            },
            settleType() {
                return this.$route.params.type;
            },  //  0 查看 1 结算 2编辑
            settleInfo() {
                let data = this.$store.state.settlement.settleInfo;
                data.settleCycleDate = [new Date(data.startTime), new Date(data.endTime)];
                let amount = 0;
                let paySettleDetail = data.paySettlementDetailDtoList;
                if(paySettleDetail && paySettleDetail.length > 0) {
                    paySettleDetail.forEach(item => {
                        if(item.driverSettlementAmount < 0) item.driverSettlementAmount = 0
                        amount += parseInt(item.driverSettlementAmount * 100)
                    })
                    /* eslint-disable */
                    this.totalAmount = amount/100
                    this.settleInfoList = paySettleDetail;
                    /* eslint-enable */
                }

                return data
            },
            driverInfoList() {
                let data = this.$store.state.settlement.driverInfoList;
                return data
            },
            settleState() {
                let data = this.$store.state.settlement.settleState;
                return data
            },
            createSettleState(){
                let data = this.$store.state.settlement.createSettle;
                return data
            },
            delPaySettleDetail(){
                let data = this.$store.state.settlement.delPaySettleDetail;
                return data
            }
        },
        filters: {
            statusDesc(value) {
                let ret = dataInfo.settleStateList.filter((item) => item.value === value)
                if (ret.length > 0)
                    return ret[0].title
                return ''
            }
        },
        //主件被加载完成
        mounted: function () {
            this.initData()
        },
        //定义事件方法
        methods: {
            initData() {
                this.$store.dispatch('settleInfo', {id: this.settleId, _this: this})
            },
            changeSettleAmount(value) {
                if(!value) {
                    return;
                }
                this.settleInfo.settlementAmount = parseFloat(value).toFixed(2);
            },  // 结算总金额格式化
            handleDelSettleBtn(info, idx) {
                let list = this.settleInfoList;
                list.forEach((item, index) => {
                    if (idx == index) {
                        this.$refs.delSelectDriver.delFleetId(item.id)
                        if(info.id){
                            let form = {
                                id: info.id
                            }
                            this.$store.dispatch('delPaySettleDetail', {form, _this: this})

                            if(this.delPaySettleDetail){
                                this.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                            }
                        }
                        list.splice(index, 1)
                    }
                })

            },
            handleSelectSettleList(item) {
                this.settleInfoList[item.index].driverSettlementAmount = parseFloat(item.value).toFixed(2);
                let amount = 0
                this.settleInfoList.forEach(list => {
                    if(list.driverSettlementAmount < 0) list.driverSettlementAmount = 0;
                    if(list.driverSettlementAmount) {
                        amount += parseInt(list.driverSettlementAmount * 100)
                    }
                })
                this.totalAmount = amount/100;
            },
            addSettleInfo() {
                let form = {}
                this.$store.dispatch('driverInfoList', {form, _this: this})
                this.isShow = true
            },
            handleCancelBtn() {
                this.isShow = false
            },  // 取消 选择司机
            handleSelectList(list) {
                // let settleInfo = [];
                let settleList = this.settleInfoList;
                if(settleList && settleList.length > 0) {
                    settleList.forEach((item) => {
                        console.log(item);
                        list.forEach((item1, idx) => {
                            if(item.driverSettlementId == item1.driverSettlementId) {
                                list.splice(idx, 1)
                            }
                        })
                    })
                }
                this.settleInfoList = [...this.settleInfoList, ...list]
                this.isShow = false
            },  // 选择司机

            submitSettleBtn() {
                console.log(this.settleInfoList,"00000000");
                if(this.settleInfoList && this.settleInfoList.length > 0) {
                    if((this.totalAmount * 100) != (this.settleInfo.settlementAmount * 100)) {
                        this.settleShow = false
                        this.$message.error('结算总金额与详情金额总和不一致!');
                        return;
                    }
                    let form = {
                        id: this.settleId
                    }
                    this.$store.dispatch('settleState', {form, _this: this})
                    if (this.settleState) {
                        this.$store.commit('delete_tabs', this.$route.path);
                        this.$router.push('/settleList');
                    }
                } else {
                    this.settleShow = false
                    this.$message.error('请填写结算明细');
                    return
                }

            },  // 结算
            submitCreateBtn() {
                // for(let i = 0; i < this.settleInfoList.length; i++){
                //     this.totalAmount += parseInt(this.settleInfoList[i].driverSettlementAmount) * 100
                // }
                console.log(this.settleInfoList,"777777777");
                if(this.settleInfoList && this.settleInfoList.length > 0) {
                    if((this.totalAmount * 100) != (this.settleInfo.settlementAmount * 100)){
                        this.preserveShow = false;
                        this.$message.error('结算总金额与详情金额总和不一致!');
                        return;
                    } else {
                        this.settleInfo.paySettlementDetailDtoList = this.settleInfoList
                        let form = JSON.parse(JSON.stringify(this.settleInfo));
                        delete form.settleCycleDate
                        this.$store.dispatch('createSettle', {form, _this: this})
                        if (this.createSettleState) {
                            this.$router.push('/settleList');
                        }
                    }
                } else {
                    this.preserveShow =false
                    this.$message.error('请填写结算明细');
                    return
                }

            },  // 保存

        },
        //监听模版变量
        watch: {}

    }
</script>

