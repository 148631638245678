<style lang="scss" scoped>

    .settle-id-card{
        display: flex;
        img{
            height: 23px;
        }
    }
</style>
<template>

    <div>
        <div class="product-list-table el-table_border_none">
            <el-table
                    type="index"
                    class="table-info"
                    border
                    :data="list"
                    v-loading="loading"
                    style="width: 100%"
            >
<!--                <el-table-column-->
<!--                        type="index"-->
<!--                        label="序号"-->
<!--                        width="50">-->
<!--                </el-table-column>-->
                <el-table-column prop="collectionName" label="司机姓名" width="100"></el-table-column>
                <el-table-column prop="reservedMobile" label="银行预留手机号" width="160"></el-table-column>
                <el-table-column prop="idCardNumber" label="身份证号" width="180"></el-table-column>
                <el-table-column label="身份证照片" width="130">
                    <template slot-scope="scope">
                        <div class="settle-id-card">
                            <img :src="scope.row.idCardPhotoBack">
                            <img :src="scope.row.idCardPhotoFace">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="driverAddress" label="身份证地址" width="260"></el-table-column>
                <el-table-column prop="collectionName" label="收款人姓名" width="120"></el-table-column>
                <el-table-column prop="bankAccount" label="收款银行卡号" width="180"></el-table-column>
                <el-table-column prop="supplierName" label="供应商" width="260"></el-table-column>


                <el-table-column label="操作" width="240">
                    <template slot-scope="scope">
                        <div class="operation-btn clear">
                            <el-button type="text"
                                       @click="editBtn(scope.row)">
                                编辑
                            </el-button>
                            <el-button
                                    style="color:#FF3B30"
                                    @click="delBtn(scope.row)"
                                    type="text"
                            >删除
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {
            }
        },
        props: {
            userInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            dataList: {
                type: Array,
                default: function () {
                    return []
                }
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        components: {},
        //计算属性
        computed: {
            list(){
                return this.dataList
            }
        },
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            editBtn(item){
                this.$emit('update:handleEditBtn', item)
            }, // 编辑
            delBtn(item){
                this.$emit('update:handleDelBtn', item)
            }, // 删除

        },
        //监听模版变量
        watch: {}

    }
</script>

