<style lang="scss" scoped>

</style>
<template>

    <div>
        <el-dialog title="编辑司机结算信息"
                   width="420px"
                   :close-on-click-modal="false"
                   :show-close="false"
                   custom-class="el-dialog-middle-center"
                   class="mod-driver-confirm"
                   :visible.sync="isShow">
            <div class="check-demand-info">
                <el-form ref="form" :model="form" size="small" label-width="130px">
                    <el-form-item label="司机姓名：">
                        <p>{{form.collectionName}}</p>
                    </el-form-item>
                    <el-form-item label="银行预留手机号：">
                        <el-input v-model="form.reservedMobile" maxlength="11" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="收款银行卡号：">
                        <el-input v-model="form.bankAccount" type="number"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancelBtn">取 消</el-button>
                <el-button size="small" type="primary" @click="submitBtn">确 定</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script type="text/ecmascript-6">

    import {isBankcardNo, isCellphone} from '@/common/js/Utils'

    export default {
        //定义模版数据
        data() {
            return {
            }
            // isBankcardNo
        },
        props: {
            isShow: {
                type: Boolean,
                default: false
            },
            info: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        components: {},
        //计算属性
        computed: {
            form(){
                return this.info
            }
        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            cancelBtn(){
                this.$emit('update:handleCancelBtn')
            },
            // handleKeyupClick(){
            //     this.form.bankAccount = this.form.bankAccount.replace(/[^\w]/g, '');
            // },

            submitBtn(){
                if(this.form.reservedMobile.length > 11){
                    this.$message.error('请输入正确的手机号');
                    return
                }else if(!isCellphone(this.form.reservedMobile)){
                    this.$message.error('请输入正确的手机号');
                    return
                }
                if(!isBankcardNo(this.form.bankAccount)){
                    this.$message.error('请输入正确的银行卡号');
                    return
                }
                    this.$emit('update:handleSubmitBtn', this.form)

            }


        },
        //监听模版变量
        watch: {}

    }
</script>

