<style lang="scss" scoped>
    .common-list-search .list-search-before .el-form-item.el-form-item--small {
        width: auto;
    }

    .settle-list-search /deep/ {
        &.common-list-search .list-search-before {
            width: calc(100% - 260px);
        }

        &.common-list-search .list-search-after {
            width: 260px;
        }
    }


</style>
<template>
    <div>
        <com-list-search class="settle-list-search">
            <div slot="list-search-before">
                <el-form :inline="true" :model="form" size="small">
                    <el-form-item label="">
                        <el-radio-group v-model="form.settlementStatus"
                                        @change="changeSettleValue">
                            <el-radio-button label="null">全部</el-radio-button>
                            <el-radio-button
                                    v-for="item in settleStateList"
                                    :label="item.value"
                                    :key="item.value">
                                {{item.title}} {{item.num}}
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item>
                        <supplier-info-input
                                :dataType="1"
                                @update:getSelectSupplier="getSelectSupplier"
                                ref="clearSupplierInfo"
                        >
                        </supplier-info-input>
                    </el-form-item>
                    <!--            <el-form-item v-if="userInfo.userType!=1">-->
                    <el-form-item>
                        <el-date-picker
                                style="width: 222px"
                                v-model="form.settleCycleDate"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="结算周期"
                                end-placeholder="结算周期">
                        </el-date-picker>
                    </el-form-item>


                    <el-form-item label="">
                        <el-input v-model="form.settlementAmount" style="width: 110px;" placeholder="结算金额"
                                  prefix-icon="icon icon-daifukuan"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="list-search-after" style="text-align: right">
                <el-button @click="exportBtn()" size="small">导出</el-button>
                <el-button @click="resetBtn()" size="small">重置</el-button>
                <el-button type="primary" @click="searchBtn()" size="small">搜索</el-button>
            </div>
        </com-list-search>


    </div>

</template>

<script type="text/ecmascript-6">

    import comListSearch from "../../../../../components/comListSearch/index.vue";
    import supplierInfoInput from "../../../../../components/supplierInfoInput/index"
    // import operatorInfoInput from "../../../fleetDetail/components/operatorInfoInput/index"


    import dataInfo from "../../../data/index"

    export default {
        //定义模版数据
        data() {
            return {
                form: {
                    settlementStatus: null,
                    supplierInfo: {},
                    settleCycleDate: [],
                    settlementAmount: ''
                },
            }
        },
        props: {},
        components: {
            comListSearch,
            supplierInfoInput,
            // operatorInfoInput
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo;
            },
            settleStateList() {
                let data = dataInfo.settleStateList;
                let objNum = this.$store.state.settlement.settleListStateNum;
                data.forEach(item => {
                    switch (item.value) {
                        case 0:
                            item.num = objNum.readyWriteCount != 0 ? objNum.readyWriteCount : '';
                            break;
                        case 1:
                            item.num = objNum.readySettlementCount != 0 ? objNum.readySettlementCount : '';
                            break;
                        case 2:
                            item.num = objNum.settlementCount != 0 ? objNum.settlementCount : '';
                            break;
                    }
                })
                return data

            },
        },
        //主件被加载完成
        mounted: function () {
            // this.initData()
        },
        //定义事件方法
        methods: {
            initData() {
                this.$store.dispatch('settleListStateNum', {_this: this})
            },
            changeSettleValue(v){
                this.$emit('update:changeSettleValue', v)
            },
            resetBtn() {
                this.form = {
                    settlementStatus: null,
                    supplierInfo: {},
                    settleCycleDate: [],
                    settlementAmount: ''
                };
                this.$refs.clearSupplierInfo.clearSupplierName();
                this.$emit('update:handleResetBtn')
            },  // 重置
            exportBtn(){
                let {
                        settlementStatus,
                        supplierInfo,
                        settleCycleDate,
                        settlementAmount
                    } = this.form,
                    form = {
                        settlementStatus
                    };
                if (settlementStatus == null) delete form.settlementStatus;
                if (supplierInfo.id) form.supplierId = supplierInfo.id;
                if (settleCycleDate && settleCycleDate.length === 2) {
                    form.startTime = +new Date(settleCycleDate[0]);
                    form.endTime = +new Date(settleCycleDate[1] + " 23:59:59");
                }
                if (settlementAmount) form.settlementAmount = settlementAmount;
                this.$emit('update:handleExportBtn', form);
            },
            searchBtn() {
                let {
                        settlementStatus,
                        supplierInfo,
                        settleCycleDate,
                        settlementAmount
                    } = this.form,
                    form = {
                        settlementStatus
                    };
                if (settlementStatus == null) delete form.settlementStatus;
                if (supplierInfo.id) form.supplierId = supplierInfo.id;
                if (settleCycleDate && settleCycleDate.length === 2) {
                    form.startTime = +new Date(settleCycleDate[0]);
                    form.endTime = +new Date(settleCycleDate[1] + " 23:59:59");
                }
                if (settlementAmount) form.settlementAmount = settlementAmount;
                this.$emit('update:handleSearchBtn', form);
            },  // 搜索
            getSelectSupplier(item) {
                this.form.supplierInfo = item
            }, // 供应商

        },
        //监听模版变量
        watch: {}

    }
</script>

