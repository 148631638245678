<style lang="scss" scoped>

</style>
<template>

    <div>
        <div class="product-list-table el-table_border_none">
            <el-table
                    type="index"
                    class="table-info"
                    border
                    :data="list"
                    v-loading="loading"
                    style="width: 100%"
            >
                <!--                <el-table-column-->
                <!--                        type="index"-->
                <!--                        label="序号"-->
                <!--                        width="50">-->
                <!--                </el-table-column>-->
                <el-table-column prop="settlementNo" label="结算记录ID" width="120"></el-table-column>
                <el-table-column label="创建时间" width="120">
                    <template slot-scope="scope">
                        <p>{{scope.row.createTime | dateCus('yyyy-MM-dd')}}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="supplierName" label="供应商" width="260"></el-table-column>
                <el-table-column label="结算周期" width="220">
                    <template slot-scope="scope">
                        <p>
                            {{scope.row.startTime | dateCus('yyyy-MM-dd')}}至{{scope.row.endTime | dateCus('yyyy-MM-dd')}}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="结算总金额" width="110">
                    <template slot-scope="scope">
                        <p>
                            ¥{{scope.row.settlementAmount | currency}}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="120">
                    <template slot-scope="scope">
                        {{scope.row.settlementStatus | statusDesc}}
                        <!--<p v-for="item in settleStateList" :key="item.value"-->
                        <!--v-if="scope.row.settlementStatus == item.value">-->
                        <!--{{item.title }}-->
                        <!--</p>-->
                    </template>
                </el-table-column>
                <el-table-column label="备注" width="120">
                    <template slot-scope="scope">
                        <el-popover
                                placement="top-start"
                                width="200"
                                trigger="hover">
                            <div style="padding: 10px;font-size: 12px;color: #000000;">
                                {{scope.row.remark}}
                            </div>
                            <el-button slot="reference" size="small" type="text"><i
                                    class="icon icon-not-adopt-tips"></i>
                            </el-button>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <div class="operation-btn clear">

                            <el-button type="text" @click="seeBtn(scope.row)">查看</el-button>
                            <el-button type="text"
                                       @click="editBtn(scope.row)"
                                       v-if="scope.row.settlementStatus != 2">
                                编辑
                            </el-button>
                            <el-button
                                    v-if="scope.row.settlementStatus == 1"
                                    @click="settleBtn(scope.row)"
                                    type="text"
                            >结算
                            </el-button>
                            <el-button
                                    style="color:#FF3B30"
                                    @click="delBtn(scope.row)"
                                    type="text"
                                    v-if="userInfo.userType==='0' && scope.row.settlementStatus != 2"
                            >删除
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

    </div>

</template>

<script type="text/ecmascript-6">
    import dataInfo from "../../../data/index"

    export default {
        //定义模版数据
        data() {
            return {
                list: []
            }
        },
        props: {
            userInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            dataList: {
                type: Array,
                default: function () {
                    return []
                }
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        filters: {
            statusDesc(value) {
                let ret = dataInfo.settleStateList.filter((item) => item.value === value)
                if (ret.length > 0)
                    return ret[0].title
                return ''
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {


        },
        //定义事件方法
        methods: {
            popoverShow(val, index) {
                console.log(index, "indexindex");
                let orderList = JSON.parse(JSON.stringify(this.list));
                console.log(orderList);
                orderList.forEach((item, idx) => {
                    item[val] = index == idx ? true : false;
                })
                console.log(orderList, "0000");
                this.list = orderList;
                this.$forceUpdate();
            },
            editBtn(item) {
                this.$emit('update:handleEditBtn', item)
            },  // 编辑
            seeBtn(item) {
                this.$emit('update:handleSeeBtn', item)
            }, // 查看
            settleBtn(item) {
                this.$emit('update:handleSettleBtn', item)
            }, // 结算
            delBtn(obj) {
                this.$emit('update:handleDelBtn', obj)
            }, // 结算


        },
        //监听模版变量
        watch: {
            dataList(n) {
                this.list = n
            }
        }

    }
</script>

